// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/vercel/path0/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/vercel/path0/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("/vercel/path0/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/vercel/path0/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-comikiri-privacypolicy-js": () => import("/vercel/path0/src/pages/comikiri_privacypolicy.js" /* webpackChunkName: "component---src-pages-comikiri-privacypolicy-js" */),
  "component---src-pages-comikiri-terms-of-use-js": () => import("/vercel/path0/src/pages/comikiri_terms_of_use.js" /* webpackChunkName: "component---src-pages-comikiri-terms-of-use-js" */),
  "component---src-pages-comikiri-update-js": () => import("/vercel/path0/src/pages/comikiri_update.js" /* webpackChunkName: "component---src-pages-comikiri-update-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("/vercel/path0/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-unicorded-privacypolicy-js": () => import("/vercel/path0/src/pages/unicorded_privacypolicy.js" /* webpackChunkName: "component---src-pages-unicorded-privacypolicy-js" */),
  "component---src-pages-unicorded-update-js": () => import("/vercel/path0/src/pages/unicorded_update.js" /* webpackChunkName: "component---src-pages-unicorded-update-js" */)
}

